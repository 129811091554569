import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO
      title="JKA Design - Colorado Architecture Firm"
      description="JKA Design focuses on creative home designs and custom solutions for house renovations. See how they can make the construction experience smoother for you.
			"
    />
    <div class="page-headline">
      <div class="container">
        <div class="section-heading text-center">
          <h6 class="font-weight-bold text-uppercase text-white-50 flair">
            About Us
          </h6>
          <h1>
            <strong>
              JKA Design - Your House Design and Home Renovation Partner in
              Colorado
            </strong>
          </h1>
        </div>
      </div>
    </div>
    <div class="page-content about-page">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-12 mb-4">
            <img
              className="jeff text-left mb-4"
              src="/assets/img/Jeff-Abrams.jpg"
              alt="Jeff Abrams"
            />
            <p>
              JKA Design LLC is passionate about helping Colorado homeowners
              improve their living space and achieving a higher quality
              lifestyle. We believe in a house that is comfortable, efficient,
              and actually serves its residents.
            </p>
            <p>
              We are an architecture firm and general contractor that believes
              in putting clients’ needs at the forefront.
            </p>
            <h2 className="pt-5 pb-3">What Can You Expect from JKA Design?</h2>
            <p>
              JKA Design LLC has a long history of accomplished and
              award-winning residential projects in Denver, Boulder, and the
              Mountain West. We are licensed architects and we work closely with
              owners, builders, interior designers, engineers, and property
              developers to create beautiful, cost-effective homes with the
              client always in mind.
            </p>
            <p>
              We start at the very beginning and make sure you are well prepared
              for these processes:
            </p>
            <ul className="">
              <li>Zoning</li>
              <li>Planning </li>
              <li>Historic applications</li>
              <li>Building permits. </li>
            </ul>
            <p>
              In the end, we take pride in creating stunning designs that allow
              you to live the lifestyle you deserve. Your home will be
              comfortable as a result of our energy conservation and
              construction ßcience background; beautiful as a result of our
              extensive knowledge, training, and widespread travels; and
              cost-effective because of our years of hands-on construction
              experience.
            </p>

            <h2 className="pt-5 pb-3">
              Jeff Abrams - The Driving Force Behind JKA Design
            </h2>

            <p>
              At the helm of this multidisciplinary design-oriented architecture
              firm is Jeff Abrams. Having lived in the Denver area for many
              years and specialized in new custom residences and homeowner
              improvements, Jeff understands the Colorado lifestyle.
            </p>
            <p>
              Jeff Abrams has been in the house and living space design and
              construction business for over 45 years now. Along his vast
              experience in architecture, Jeff holds a Master of Architecture
              degree from Yale University.
            </p>
            <p>
              He employs his knowledge and expertise in Boulder, Denver, Aspen,
              and the Front Range of Colorado. He is all about diligence and
              approaching any project, big or small, with exquisite attention to
              detail.
            </p>

            <h2 className="pt-5 pb-3">What We Constantly Strive Towards</h2>

            <p>
              Our focus is the creative, client-driven, design of each project
              and its execution. We are attuned to the authenticity of character
              and style, and we are particularly adept at the challenges
              presented by the difficult and sometimes “unbuildable” site.
            </p>

            <p>
              We are problem solvers and our in-depth knowledge of building
              science and construction technology is an outgrowth of over
              thirty-five years of innovation in the areas of energy
              conservation, green design, and a passive solar approach to all
              our buildings.
            </p>

            <p>
              We provide the inventive and creative ingenuity that translates
              our clients’ very special needs into a cost-effective, beautifully
              finished building of grace, utility, and efficiency. We enjoy the
              challenges of
            </p>
            <ul className="">
              <li>Special Structures and Complex Projects </li>
              <li>Residential Renovations </li>
              <li>Home Additions</li>
              <li>Luxury Homes On Difficult Sites</li>
            </ul>

            <p>
              We take pride in offering straightforward and beautiful solutions
              to often intricate and code restricted projects. We bring the
              client on board as an integral part of the team which results in a
              shared vision with utmost efficiency.
            </p>

            <p>
              We create unique buildings filled with comfort, commodity, and
              delight. Our best project will be yours!
            </p>

            <h2 className="pt-5 pb-3">What Our Clients Say About Us</h2>

            <p className="font-italic text-blue">
              “We love our home; we love to come home to it; it not only looks
              good, but it functions well. Jeff made many suggestions that have
              added to the value and livability of our home. For example,
              offsetting of the master bedroom so southern light could enter it,
              modifying the plan to maximize passive solar (this was before you
              heard much about passive solar), adding an additional level for
              very little $, siting the house for the best view while
              maintaining the best natural heating, cooling, and light
              features.” –Cinda K., Homeowner
            </p>

            <p>
              Hundreds of clients along the Front Range and in the Colorado
              mountains have taken advantage of Jeff’s and JKA Design’s unique
              skill-set over the years.
            </p>

            <p>
              Jeff’s design for the first solar church and subsequent
              alternative energy projects, along with many innovations in
              Passive Solar methodologies, kick-started the development of the
              Energy Conservation Group. He developed numerous design details
              and techniques for sealing and tightening homes while maximizing
              solar gain and cooling for client comfort, energy conservation,
              and cost-efficiency.
            </p>

            <p>
              The staff at JKA design are also well versed in passive solar
              design and were early adopters of creating massively insulated and
              solar receptive homes.
            </p>

            <p>
              Jeff would be delighted to discuss becoming an integral part of
              your project in order to best serve you and enhance your
              lifestyle.
            </p>
          </div>
          <div class="col-md-6 mb-4">
            <h3 className="mb-4">Blueprint for Better</h3>
            <iframe
              src="https://www.youtube.com/embed/UENtvNuN8kg?rel=0&amp;showinfo=0&amp;wmode=opaque"
              width="100%"
              height="315"
              title="Blueprint for Better"
              frameborder="0"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
          <div class="col-md-6 mb-4">
            <h3 className="mb-4">“I Am An Architect” (just for fun!)</h3>
            <iframe
              src="https://www.youtube.com/embed/6E6HHPJiVmY?rel=0&amp;showinfo=0&amp;wmode=opaque"
              width="100%"
              height="315"
              title="“I Am An Architect”"
              frameborder="0"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
